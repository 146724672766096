var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-container" }, [
        _c(
          "div",
          { staticClass: "outer" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  border: "",
                  "header-cell-style": { background: "#F0F2F8" },
                  data: _vm.tableData,
                  "element-loading-text": "拼命加载中",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "nickname", label: "微信名" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "ctime", label: "关注时间" },
                }),
                _c("el-table-column", {
                  attrs: { label: "消息类型", prop: "typeContent" },
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "190" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "margin-bottom": "10px",
                                    color: "#20A0FF",
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" 编辑 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { color: "#E02020" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDel(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.pagination.currentPage,
                "page-sizes": [10, 20, 30, 50],
                "page-size": _vm.pagination.nowPageSize,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.pagination.count,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择你的消息类型",
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.typeValue,
                    callback: function ($$v) {
                      _vm.typeValue = $$v
                    },
                    expression: "typeValue",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "1" } }, [
                    _vm._v(" 发货消息 "),
                  ]),
                  _c("el-checkbox", { attrs: { label: "2" } }, [
                    _vm._v(" 财务消息 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.editType(_vm.editId)
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }