<template>
  <div>
    <div class="app-container">
      <div class="outer">
        <!-- 列表 -->
        <el-table
          v-loading="loading"
          border
          :header-cell-style="{background: '#F0F2F8'}"
          :data="tableData"
          element-loading-text="拼命加载中"
          style="width:100%;"
        >
          <el-table-column
            prop="nickname"
            label="微信名"
          />
          <el-table-column
            prop="ctime"
            label="关注时间"
          />
          <el-table-column
            label="消息类型"
            prop="typeContent"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            width="190"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  type="text"
                  style="margin-bottom: 10px;color: #20A0FF;"
                  @click="openEdit(scope.row)"
                >
                  编辑
                </el-button>
              </div>
              <div>
                <el-button
                  type="text"
                  style="color: #E02020"
                  @click="openDel(scope.row.id)"
                >
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pagination.nowPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.count"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!--编辑弹窗-->
    <el-dialog
      title="请选择你的消息类型"
      :visible.sync="dialogFormVisible"
    >
      <div>
        <el-checkbox-group v-model="typeValue">
          <el-checkbox label="1">
            发货消息
          </el-checkbox>
          <el-checkbox label="2">
            财务消息
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="editType(editId)"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchCard from '@/components/common/SearchCard'
export default {
  name: 'MessageList',
  data() {
    return {
      loading: true,
      tableData: [],
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      },
      dialogFormVisible: false,
      typeValue: [],
      visible: false,
      editId: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    },
    // 列表数据
    getList(index) {
      this.loading = true
      this.$axios.post(this.$api.Msg.get_user_list, {
        page: this.pagination.currentPage,
        pagecount: this.pagination.nowPageSize
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          const newData = r.data.list
          newData.forEach(el => {
            const arr = []
            el.msg_type.split(',').forEach(t => {
              if (t === '1') {
                arr.push('发货消息')
              } else if (t === '2') {
                arr.push('财务消息')
              }
            })
            el.typeContent = arr.join('，')
          })
          this.tableData = newData
          this.pagination.count = r.data.pagination.item_total
        } else {
          this.tableData = []
        }
        this.loading = false
      })
    },
    // 编辑消息类型
    editType() {
      this.$axios.post(this.$api.Msg.edit_msg_type, {
        id: this.editId,
        type_ids: this.typeValue.join(',')
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          this.$message('修改成功')
          this.dialogFormVisible = false
          this.getList()
        }
      })
    },
    openEdit(row) {
      this.dialogFormVisible = true
      this.editId = row.id
      this.typeValue = row.msg_type.split(',')
    },
    openDel(id) {
      this.$confirm('是否删除该微信号与管理后台账户的关联关系？', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.delMsg(id)
      }).catch(() => {
      })
    },
    // 删除消息
    delMsg(id) {
      this.$axios.post(this.$api.Msg.del_msg_type, {
        id
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.visible = false
          this.getList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
